<template>
	<div>
	
		<div class="content content_top_margin">
			<div class="content_inner  ">
				<div class="full_width ">
					<div class="full_width_inner">

						<div class="vc_row wpb_row section vc_row-fluid sa-bgimg grid_section"
							style=' text-align:left;'>
							<div class=" section_inner clearfix">
								<div class='section_inner_margin clearfix'>
									<div class="wpb_column vc_column_container vc_col-sm-12">
										<div class="vc_column-inner">
											<div class="wpb_wrapper">
												<div class="wpb_text_column wpb_content_element ">
													<div class="wpb_wrapper">
														<h1>Give us 10 minutes of your time<br />
															we’ll give you a better you.</h1>

													</div>
												</div>
												<div class="vc_empty_space" style="height: 50px"><span
														class="vc_empty_space_inner">
														<span class="empty_space_image"></span>
													</span></div>

												<div class="vc_row wpb_row section vc_row-fluid vc_inner  info-boxes vc_custom_1636575661541"
													style=' text-align:left;'>
													<div class=" full_section_inner clearfix">
														<div class="wpb_column vc_column_container vc_col-sm-12">
															<div class="vc_column-inner">
																<div class="wpb_wrapper">
																	<div class="wpb_text_column wpb_content_element ">
																		<div class="wpb_wrapper">
																			<iframe :src="company_data.formlink_self_assessment+'?firstname='+this.user_data.first_name+'&lastname='+this.lastName+'&email='+this.user_data.email" frameborder="0" style="width: 100%; height: 649px; margin-top: 67px;"></iframe>
																			<!-- <iframe																			
																				src='https://pbergo.sjc1.qualtrics.com/jfe/form/SV_4JBnRE9X2h4msp7?Employee_First_Name=&Employee_Last_Name=&Employee_Email=Galaxy1%40pbergo.com&First_Name=&Last_Name=&Email=Galaxy1%40pbergo.com'
																				width='100%' height='1200px'
																				frameborder='0'
																				style='background-color: transparent'
																				allowtransparency='true'>
																				</iframe> -->
																				<!-- <iframe :src="company_data.formlink_self_assessment" frameborder="0"></iframe> -->

																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="vc_row wpb_row section vc_row-fluid sa-bgwhite grid_section"
							style=' text-align:left;' v-if="company_data.schedule_request_status">
							<div class=" section_inner clearfix">
								<div class='section_inner_margin clearfix'>
									<div class="wpb_column vc_column_container vc_col-sm-12">
										<div class="vc_column-inner">
											<div class="wpb_wrapper">
												<div class="wpb_text_column wpb_content_element ">
													<div class="wpb_wrapper">
														<h2 style="text-align: center;">Already completed a
															self-assessment?</h2>
														<p style="text-align: center;">Already completed your
															self-assessment, but still need a bit more help? We’ve got
															you covered. Schedule a one-on-one ergo evaluation with one
															of our leading ergonomists.</p>
														<p>&nbsp;</p>
														<p style="text-align: center;">
															<router-link :to="{ name: 'schedule-evaluation-a45' }" class="button red">
																Schedule an Evaluation 
															</router-link> 
														</p>

													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>



					</div>
				</div>
			

			</div>
		</div>

	</div>
</template>
<script>
	//import carousel from "vue-owl-carousel"
	export default {
		name: 'self-assessment-a45',
		data() {
        return {
            company_data: this.$store.getters["auth/authUser"].client ?? null,
			user_data:[],
			lastName:"",
        };
    },
		mounted(){
        // this.company_data = JSON.parse(localStorage.getItem("companyInformation"));  
		document.title = "PBErgo - "+this.$router.currentRoute.meta.page_title;
		let data = this.$store.getters["auth/authUser"];
		this.company_data = data.client;
		this.user_data = data.user;
		this.lastName = this.user_data.last_name ? this.user_data.last_name : "";
      },
		methods: {
			hideModal() {
				// this.$emit('hide');
				document.getElementById("closed").src += "";
			},
		}
	}
</script>


   